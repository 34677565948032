<template>
  <div class="page-404">
    <div class="page-404__logo">
      <router-link :to="{name: 'dashboard'}">
        <SvgIcon
          name="uplify-logo"
          width="64"
          height="22"
        />
      </router-link>
    </div>

    <div class="page-404__inner">
      <div class="headline page-404__title">
        {{ locale.pageNotFound }}
      </div>
      <img
        class="page-404__image"
        :src="require('assets/img/404.png')"
        alt="404"
      >
      <ElButton
        class="page-404__button"
        type="primary"
        @click="$router.push('/')"
      >
        {{ locale.back }}
      </ElButton>
      <div class="text-center text-s-regular">
        {{ locale.question }}
        <a
          href="https://discord.gg/uplify"
          target="_blank"
        >
          {{ locale.chat }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ElButton from 'element/ElButton'

export default {
  components: {
    ElButton,
  },

  computed: {
    locale: ({ $rootLocale }) => $rootLocale('views.page404'),
  },
}
</script>

<style lang="scss" scoped>
.page-404 {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;

  &__logo {
    position: absolute;
    top: 32px;
    left: 24px;

    svg {
      fill: $--color-primary;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__title {
    margin-bottom: 48px;
  }

  &__image {
    height: 240px;
  }

  &__button {
    width: 320px;
    margin-bottom: 16px;
  }
}
</style>
